<template>
	<div id="app">
		<!-- <img alt="Vue logo" src="./assets/logo.png"> -->
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<!-- @loaded -->
		<!-- @page-loaded -->
		<!-- @num-pages="numPages" -->
		<!-- v-for="i in totleNum" -->
		<!-- <img style="width: 200px;height: 200px;" src="@/assets/logo.png" alt=""> -->
		<template>
			<div style="height: calc(100vh - 50px);overflow-y: scroll;" ref="container" @scroll="handleScroll">
				<pdf v-for="i in totleNum" :key="i" :page="i" @loaded="loaded" @page-loaded="pageLoaded"
					:src="pdfUrl"></pdf>
				<!-- <pdf :key="1" :page="10"
					:src="pdfUrl"></pdf> -->
			</div>
			
			<div @click="submit" class="bottomBtn">
				{{btn}}
			</div>
		</template>
	</div>
</template>

<script>
	// import HelloWorld from './components/HelloWorld.vue'
	import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js";
	import pdf from 'vue-pdf'
	export default {
		name: 'App',
		components: {
			// HelloWorld
			pdf
		},
		data() {
			return {
				// pdfUrl:'https://cdqyn.vvv6g.cn/files/20230913/9026ed523a1b23b3d598715dbbfb64d1.pdf',
				pdfUrl: '',
				totleNum: 1,
				totleNum2:1,
				isLoading:false,
				isReadEnd:false,
				btn:'',
				state:'',
				alertContent:'',
			}
		},
		created() {
			// this.pdfUrl = 'https://psjzl.wto5g.com/uploads/pdf/1700294311.pdf';
			try {
				// 接收小程序消息
				my.onMessage = res => {
					// this.pdfUrl = ;
					this.pdfUrl = pdf.createLoadingTask({
						url: res.pdfUrl,
						CMapReaderFactory
					})
					this.pdfUrl.promise.then(pdf => {
						this.totleNum2 = pdf.numPages;
					});
					this.btn = res.btn;
					this.state = res.state;
					this.alertContent = res.alertContent;
				}
				// // 显示加载动画
				// my.showLoading({
				// 	content: '加载中...',
				// 	success: function(res) {},
				// 	fail: function(err) {}
				// });
				// 向小程序发送消息
				my.postMessage({
					type: "created"
				});
			} catch (e) {
				alert('请在支付宝小程序中使用！');
				this.pdfUrl = pdf.createLoadingTask({
					url: 'https://psjzl.wto5g.com/uploads/pdf/1700294311.pdf',
					// CMapReaderFactory,
					// cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',//引入pdf.js字体
					cMapUrl: 'https://spzl.wto5g.com/cmaps/',//引入pdf.js字体
					
					cMapPacked: true
				})
				this.pdfUrl.promise.then(pdf => {
					this.totleNum2 = pdf.numPages;
				});
				// this.pdfUrl = 'https://psjzl.wto5g.com/uploads/pdf/1700294311.pdf';
				// this.pdfUrl = 'https://cdqyn.vvv6g.cn/files/20230913/9026ed523a1b23b3d598715dbbfb64d1.pdf';
			}
			
		},
		mounted() {
			
		},
		methods: {
			// 点击按钮
			submit() {
				if (this.alertContent != '' && !this.isReadEnd) {
					my.alert({
						content: this.alertContent,
						buttonText: '我知道了',
						success: () => {
			
						}
					});
					return;
				}
				// 向小程序发送消息
				my.postMessage({
					type: "submit"
				});
			},
			handleScroll() {
				let {
					scrollTop,
					clientHeight,
					scrollHeight
				} = this.$refs.container;
				console.log(scrollTop,clientHeight,scrollHeight)
				if (scrollTop + clientHeight >= scrollHeight-50) {
					this.isReadEnd = true;
					// alert('已读完');
				}
			},
			numPages(e) {
				// console.log('numPages', e);
				// this.totleNum = e;
			},
			loaded(e) {
				// console.log('loaded', e);
			},
			pageLoaded(e) {
				if(this.totleNum<this.totleNum2){
					this.totleNum++;
				}
				if(this.totleNum == this.totleNum2){
					this.handleScroll();
					this.isLoading = true;
				}
			},

		}
	}
</script>

<style>
	*{
		margin: 0;
		padding: 0;
	}
	.bottomBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		background: #e75c60;
		color: #fff;
		font-weight: 700;
	}
</style>